<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="blog_loading"
  >
    <template slot="actions">
      <div slot="actions">
        <div class="field has-addons">
          <div class="is-flex mr-2">
            <b-button
              v-if="$device.mobile"
              size="is-small"
              rounded
              @click="showMobile()"
            >
              <b-icon
                icon="magnify"
                size="is-small"
              />
            </b-button>
            <b-input
              ref="search"
              v-model="search"
              :placeholder="$t('search')"
              icon="magnify"
              :size="$device.mobile ? 'is-small' : ''"
              :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
              rounded
              @blur="hideMobile()"
            />
          </div>
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            scrollable
            max-height="400px"
            append-to-body
          >
            <div slot="trigger">
              <b-button
                icon-left="dots-vertical"
                :size="$device.mobile ? 'is-small' : ''"
                type="is-default"
                rounded
              />
            </div>

            <b-dropdown-item
              v-if="$can('create', 'blog')"
              aria-role="listitem"
              has-link
            >
              <router-link to="/blog/articles/create">
                <b-icon
                  icon="plus"
                  size="is-small"
                />
                {{ $t("new_article") }}
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              :focusable="false"
            >
              <b-switch
                v-model="active"
                type="is-success"
              >
                {{
                  $tc("active", 2)
                }}
              </b-switch>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </template>
    <Card content-class="c-table">
      <b-table
        :loading="blog_loading"
        :data="articles"
        sotrable
        default-sort="created_at.datetime"
        default-sort-direction="desc"
        paginated
        pagination-position="top"
        :per-page="perPage"
        :current-page="page"
        :total="total"
        :row-class="
          (row, index) => (row.id == updated_blog ? 'bg-updated' : '')
        "
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value"
            v-slot="props"
            :label="head.text"
            :field="head.value"
            :sortable="head.sortable"
            :width="head.width"
            :centered="head.centered"
            header-class="sticky-header"
          >
            <template v-if="head.value == 'site_id'">
              <img
                height="20"
                width="20"
                :src="'/img/sites/' + props.row.site_id + '.svg'"
              >
            </template>
            <template v-else-if="head.value == 'id'">
              <router-link :to="`/blog/articles/${props.row.id}`">
                {{ props.row.id }}
              </router-link>
            </template>
            <template v-else-if="head.value == 'agent.signature'">
              {{ props.row.agent && props.row.agent.signature }}
            </template>
            <template v-else-if="head.value == 'created_at.datetime'">
              {{ props.row.created_at && props.row.created_at.formatted }}
            </template>
            <template v-else-if="head.value == 'actions'">
              <b-dropdown
                :key="props.row.id"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  v-if="$can('read', 'blog')"
                  :href="props.row.url"
                  target="_blank"
                  replace
                  aria-role="listitem"
                >
                  <b-icon
                    icon="eye"
                    size="is-small"
                  />
                  {{ $t("view") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('update', 'blog')"
                  aria-role="listitem"
                  has-link
                >
                  <router-link :to="`/blog/articles/${props.row.id}`">
                    <b-icon
                      icon="pencil"
                      size="is-small"
                    />
                    {{ $t("edit") }}
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="active && $can('delete', 'blog')"
                  aria-role="listitem"
                  @click="confirmDeleting(props.row.id)"
                >
                  <b-icon
                    icon="delete"
                    type="is-danger"
                    size="is-small"
                  />
                  {{ $t("delete") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!active && $can('update', 'blog')"
                  aria-role="listitem"
                  @click="restoreBlog(props.row.id)"
                >
                  <b-icon
                    icon="refresh"
                    type="is-success"
                    size="is-small"
                  />
                  {{ $t("restore") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-else>
              {{ props.row[head.value] }}
            </template>
          </b-table-column>
        </template>
        <EmptyBlock
          slot="empty"
          icon="post"
        />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="page"
        @update-per-page="val => (perPage = val)"
        @update-current-page="val => (page = val)"
      />
    </Card>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      searchFocused: false,
      search: "",
      total: 0,
      perPages: [50, 100, 200],
      perPage: 50,
      page: 1,
      blog_loading: true,
      active: true,
      items: [],
      articles: [],
      sites: [],
      categories: [],
      in_progress: null,
      updated_blog: null,
      headers: [
        {
          text: this.$i18n.t("site"),
          value: "site_id",
          sortable: true,
          numeric: true,
          width: "50",
          centered: true
        },
        {
          text: this.$i18n.t("id"),
          value: "id",
          sortable: true,
          numeric: true,
          width: "60"
        },
        {
          text: this.$i18n.tc("agent", 1),
          value: "agent.signature",
          sortable: true,
          numeric: false,
          width: "200"
        },
        {
          text: this.$i18n.t("title"),
          value: "title",
          sortable: true,
          numeric: false,
          width: ""
        },
        {
          text: this.$i18n.t("views"),
          value: "views",
          sortable: true,
          numeric: true,
          width: "150"
        },
        {
          text: this.$i18n.t("created_at"),
          value: "created_at.datetime",
          sortable: true,
          numeric: false,
          width: "200"
        },
        {
          text: this.$i18n.t("actions"),
          value: "actions",
          sortable: false,
          numeric: false,
          width: "80",
          centered: true
        }
      ]
    };
  },
  watch: {
    active() {
      this.getBlog();
    },
    search() {
      this.filterSearch();
    }
  },
  created() {
    this.updateTitle(this.$tc("article", 2));
  },
  mounted() {
    this.getBlog();
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    confirmDeleting(id) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteBlog(id)
      });
    },
    filterSearch() {
      if (this.search.length) {
        this.articles = this.items.filter(
          a =>
            JSON.stringify(a)
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) != -1
        );
      } else {
        this.articles = this.items;
      }
      this.total = this.articles.length;
    },
    getStockColor(stock) {
      if (stock <= 2) return "is-danger";
      else if (stock <= 6) return "is-warning";
      else return "is-success";
    },
    getBlog() {
      if (this.$can("read", "blog")) {
        this.blog_loading = true;
        let query = "";
        if (!this.active) query += "?active=0";

        this.$axios
          .get("blog" + query)
          .then(res => {
            this.items = res.data.list ? res.data.list : [];
            this.filterSearch();
            this.sites = res.data.filters.sites ? res.data.filters.sites : [];
            this.categories = res.data.filters.categories
              ? res.data.filters.categories
              : [];
          })
          .catch(e => this.clientError(e))
          .finally(() => (this.blog_loading = false));
      }
    },
    deleteBlog(id) {
      this.in_progress = id;
      this.$axios
        .delete("blog/" + id)
        .then(() => {
          this.items = this.items.filter(i => i.id !== id);
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
          this.filterSearch();
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.in_progress = null));
    },
    restoreBlog(id) {
      this.in_progress = id;
      this.$axios
        .patch("blog/" + id + "/restore")
        .then(() => {
          this.items = this.items.filter(i => i.id !== id);
          this.$root.notify(this.$t("successfully_restored"), "is-success");
          this.filterSearch();
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.in_progress = null));
    }
  }
};
</script>
